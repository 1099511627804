<template>
  <div class="Cumulative_achievements">
    <div class="cu-content">
      <div class="group">
        <div class="text flex">
          <img src="../../assets/images/enterprise/task/jifen.png" alt="" />
          <div>今日积分+{{ today }}</div>
        </div>
        <div class="text tips">累计积分：{{ count }}分</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      today: "",
      count: "",
    };
  },
  mounted() {
    this.getScoreCount();
  },
  methods: {
    getScoreCount() {
      this.$api.score.count().then((res) => {
        let { today, count } = res.data;
        this.today = today;
        this.count = count;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.Cumulative_achievements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  .cu-content {
    width: 50%;
    margin: 0 auto;
    background: url("../../assets/images/enterprise/integral/bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 5vw 0;
    .group {
      font-size: 35px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #fefeff;
      .text {
        line-height: 3vw;
      }
      .flex {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tips {
        font-size: 23px;
      }
    }
  }
}
</style>
